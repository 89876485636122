
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { isRequired, isNotEmpty, validateLength } from '@/utils/validators'
import {
  getCompanyContactInfo,
  createContactLog,
  getReferralDriverContactInfo,
} from '@/services/tripMonitoring'
import { DateTime } from 'luxon'
import customers from '@/services/customers'
import { CustomerContactJourneyEvent, CustomerContactLog, LiveTripEventCategory } from '@/models/dto/LiveTripEvent'
import { createLiveTripEvent } from '@/services/tripMonitoring'

export default {
  props: {
    reservationId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    journey: {
      type: Object,
      default: null,
    }
  },
  computed: {
    journeyId() {
      return this.journey?.externalJourneyId
    },
    shouldShowOperatorContactPreferences() {
      return this.mode === 'operator' || this.mode === 'driver'
    },
    shouldShowEmailDropdown() {
      return this.mode === 'operator' || this.mode === 'customer'
    },
    shouldShowAICallBtn() {
      return this.mode === 'customer'
    }
  },
  data() {
    return {
      contactPreferenceText: '',
      customerEmails: [],
      selectedCustomerEmail: '',
      contactEmail: '',
      contactEmails: [],
      contactTime: null,
      contactMethod: 'none',
      contactLogText: '',
      contactSetReminderEnabled: false,
      reminderTime: null,
      loading: true,
      submitting: false,
      customerContactType: 'BOOKING',
      contactMethodMap: [
        { text: 'None', value: 'none' },
        { text: 'Phone', value: 'phone' },
        { text: 'SMS', value: 'sms' },
        { text: 'Email', value: 'email' },
      ],
      customers: [],
      selectedCustomer: null,
      contactPhoneNumberItems: [],
      selectedPhoneNumber: null,
      validateLength,
    }
  },
  async created() {
    switch (this.mode) {
      case 'driver':
        const contactInfoResponse = await getReferralDriverContactInfo(
          this.companyId,
          this.reservationId
        )
        const driverContactInfo = contactInfoResponse.data
        this.contactPreferenceText = driverContactInfo.contactPreferenceText
        this.contactPhoneNumberItems = driverContactInfo.driverContactInfo
          .filter((contactInfo) => !!contactInfo.phone)
          .map((contactInfo) => ({
            text: `${contactInfo.phone} (${contactInfo.firstName} ${contactInfo.lastName})`,
            value: contactInfo.phone,
          }))
        break
      case 'operator':
        const operatorContactInfoResponse = await getCompanyContactInfo(
          this.companyId
        )
        const companyContactInfo = operatorContactInfoResponse.data
        this.contactPreferenceText = companyContactInfo.contactPreferenceText
        const potentialContactPhoneNumbers = [
          {
            phone: companyContactInfo.phone,
            footer: '',
          },
          {
            phone: companyContactInfo.opsPhone,
            footer: '(Ops)',
          },
          {
            phone: companyContactInfo.partnerPhone,
            footer: '(Partner)',
          },
          {
            phone: companyContactInfo.smsPhone,
            footer: '(SMS)',
          },
        ]

        this.contactPhoneNumberItems = potentialContactPhoneNumbers
          .filter((companyPhone) => !!companyPhone.phone)
          .map((companyPhone) => ({
            text: `${companyPhone.phone} ${companyPhone.footer}`.trim(),
            value: companyPhone.phone,
          }))
        this.contactEmails = [companyContactInfo.email]
        this.contactEmail = this.contactEmails[0]
        break

      case 'customer':
          this.contactPhoneNumberItems = []

          const bookingContactName = `${this.journey.bookingContactFirstName || ''} ${this.journey.bookingContactLastName || ''}`.trim()
          const tripContactName = `${this.journey.tripContactFirstName || ''} ${this.journey.tripContactLastName || ''}`.trim()
          const { tripContactEmail, bookingContactEmail, tripContactPhone, bookingContactPhone } = this.journey

          // Set up phone numbers
          if (bookingContactPhone) {
            this.contactPhoneNumberItems.push({
              text: `Booking Contact: ${bookingContactName}, ${bookingContactPhone}`,
              value: bookingContactPhone,
            })
          }
          if (this.journey.tripContactPhone) {
            this.contactPhoneNumberItems.push({
              text: `Trip Contact: ${tripContactName}, ${tripContactPhone}`,
              value: tripContactPhone,
            })
          }

          // Set up emails
          if (this.journey.tripContactEmail) {
            this.contactEmails.push({
              text: `Trip Contact: ${tripContactName}, ${tripContactEmail}`,
              value: tripContactEmail
            })
          }


          if (bookingContactEmail) {
            this.contactEmails.push({
              text: `Booking Contact: ${bookingContactName}, ${bookingContactEmail}`,
              value: bookingContactEmail
            })
          }

          if (bookingContactName) {
            this.customers.push({
              text: bookingContactName,
              value: 'BOOKING',
            })
          }

          if (tripContactName) {
            this.customers.push({
              text: tripContactName,
              value: 'TRIP',
            })
          }

          if (bookingContactName || tripContactName) {
            this.selectedCustomer = this.customers[0]
          }

        break
      default:
        this.showAlert({
          type: 'error',
          message: 'UI error, could not determine who to contact',
        })
    }
    this.selectedPhoneNumber = this.contactPhoneNumberItems[0]?.value
    this.loading = false
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    isRequired,
    isNotEmpty,
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    copySelectedPhoneNumber() {
      navigator.clipboard.writeText(this.selectedPhoneNumber)
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Phone number copied to your clipboard',
      })
    },
    copyEmail() {
      navigator.clipboard.writeText(this.contactEmail)
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Email copied to your clipboard',
      })
    },
    // TODO: AI Calling Customers has not been implemented yet, so this is a placeholder
    handleAICall() {
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: `AI Call requested to ${this.selectedPhoneNumber}`,
      })
    },
    buildTripMonitoringEvent(reminderDatetime: string = null): CustomerContactJourneyEvent {
      if (this.mode !== 'customer') {
        return null
      }

      const currentUser = this.$store.getters['auth/currentUser']

      const customerContactLogInfo: Partial<CustomerContactLog> = {
        occurredOn: DateTime.now().toISO(),
        loggedByUserId: currentUser.userId,
        loggedByUserName: `${currentUser.firstName} ${currentUser.lastName}`.trim(),
        contactName: this.selectedCustomer.text,
        customerContactType: this.selectedCustomer.value,
        contactMethod: this.contactMethod,
        note: this.contactLogText,
      }

      const customerContactEvent = new CustomerContactJourneyEvent(customerContactLogInfo)
      if (this.contactSetReminderEnabled && this.reminderTime) {
        customerContactEvent.setAlert(true, LiveTripEventCategory.OTHER, reminderDatetime)
      }

      return customerContactEvent
    },
    async submit() {
      if (!this.$refs['contact-form'].validate()) {
        return
      }
      this.submitting = true

      const contactDatetime = DateTime.fromISO(this.contactTime, { setZone: true }).setZone("America/New_York", { keepLocalTime: true} ).toISO()
      const reminderDatetime = DateTime.fromISO(this.reminderTime, { setZone: true }).setZone("America/New_York", { keepLocalTime: true} ).toISO()
      let contactType = this.mode
      if (contactType === 'customer') {
        contactType = this.selectedCustomer.value === 'BOOKING' ? 'booking_customer' : 'trip_contact_customer'
      }

      const payload = {
        companyId: this.companyId,
        contactPreferenceText: this.contactPreferenceText,
        reservationId: this.reservationId,
        contactLogText: this.contactLogText,
        contactMethod: this.contactMethod,
        contactType,
        contactTime: contactDatetime,
        reminderTime: this.contactSetReminderEnabled ? reminderDatetime : null,
      }

      try {
        const createContactLogResponse = await createContactLog(payload)
        if (this.mode === 'customer') {
          const event = this.buildTripMonitoringEvent(reminderDatetime)
          const createTripMonitoringEventResponse = await createLiveTripEvent(this.journeyId, event)
        }

        EventBus.$emit('global-table-view-refresh', {
          type: 'success',
          message: 'Contact log successfully added',
        })
      } catch (e) {
        const message = 'Error adding contact log'
        this.showAlert({
          type: 'error',
          message: message,
        })
        console.error(e)
        this.submitting = false
        return
      }

      this.submitting = false
      this.close()
    },
  },
}
