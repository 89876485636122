export enum LiveTripEventType {
  NEW_ETA = 'NEW_ETA',
  LATE_ETA = 'LATE_ETA',
  ON_TIME_ETA = 'ON_TIME_ETA',
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_UNASSIGNED = 'DRIVER_UNASSIGNED',
  CUSTOMER_CONTACT = 'CUSTOMER_CONTACT',
  ARRIVAL = 'ARRIVAL',
  LATE_ARRIVAL = 'LATE_ARRIVAL',
  ON_TIME_ARRIVAL = 'ON_TIME_ARRIVAL'
}


export enum LiveTripEventCategory {
  DRIVER = 'DRIVER',
  TRACKING = 'TRACKING',
  VEHICLE = 'VEHICLE',
  ON_TIME = 'ON_TIME',
  OTHER = 'OTHER',
}

export enum JourneyEventStatus {
  ACTIVE = 'ACTIVE',
  DISMISSED = 'DISMISSED',
  SNOOZED = 'SNOOZED',
}

export interface BaseEvent {
  id?: string
  occurredOn: string
  type: LiveTripEventType
  alerts: EventAlert[]
}

export interface EventAlert {
  id?: string
  type: LiveTripEventCategory
  status: JourneyEventStatus
  triggeredAt: string
  snoozedAt: string
  snoozedUntil: string | null
}

export interface CreateLiveTripJourneyEventRequest {
  journeyEvent: BaseEvent | NewEtaJourneyEvent | CustomerContactJourneyEvent
}

export interface CreateLiveTripJourneyEventResponse {
  journeyEvent: BaseEvent | NewEtaJourneyEvent
}

export interface NewEtaJourneyEvent extends BaseEvent {
  waypointId: string
  eta: string
  timeZone: string
}

export interface CustomerContactLog extends BaseEvent {
  loggedByUserId: string
  loggedByUserName: string
  contactName: string
  customerContactType: string
  contactMethod: string
  note: string
}
export interface NewArrivalJourneyEvent extends BaseEvent {
  waypointId: string
  arrivedOn: string
  timeZone: string
}

export class LiveTripEvent implements BaseEvent {
  id?: string
  occurredOn: string
  type: LiveTripEventType
  alerts: EventAlert[]

  constructor(params?: Partial<BaseEvent>) {
    this.occurredOn = params?.occurredOn ?? new Date().toISOString()
    this.alerts = params?.alerts ?? []
    this.type = params?.type ?? null
    this.id = params?.id
  }

  setAlert(shouldAddAlert: boolean, alertType?: LiveTripEventCategory, alertTime?: string) {
    if (!shouldAddAlert) {
      this.alerts = []
      return
    }

    if (!alertTime) {
      throw new Error('alertTime is required when adding an alert')
    }

    const currentTime = new Date().toISOString()

    this.alerts = [{
      type: alertType,
      status: JourneyEventStatus.SNOOZED,
      triggeredAt: currentTime,
      snoozedAt: currentTime,
      snoozedUntil: alertTime
    }]
  }

}

export class EtaJourneyEvent extends LiveTripEvent implements NewEtaJourneyEvent {
  waypointId: string
  eta: string
  timeZone: string

  constructor(params?: Partial<NewEtaJourneyEvent>) {
    super()
    this.waypointId = params?.waypointId ?? ''
    this.eta = params?.eta ?? ''
    this.timeZone = params?.timeZone ?? ''
    this.type = LiveTripEventType.NEW_ETA
    this.alerts = params?.alerts ?? []
  }

}

export class CustomerContactJourneyEvent extends LiveTripEvent implements CustomerContactLog {
  loggedByUserId: string
  loggedByUserName: string
  contactName: string
  customerContactType: string
  contactMethod: string
  note: string

  constructor(params?: Partial<CustomerContactLog>) {
    super()
    this.loggedByUserId = params?.loggedByUserId ?? ''
    this.loggedByUserName = params?.loggedByUserName ?? ''
    this.contactName = params?.contactName ?? ''
    this.customerContactType = params?.customerContactType ?? ''
    this.contactMethod = params?.contactMethod ?? ''
    this.note = params?.note ?? ''
    this.type = LiveTripEventType.CUSTOMER_CONTACT
  }
}
export class ArrivalJourneyEvent extends LiveTripEvent implements NewArrivalJourneyEvent {
  waypointId: string
  arrivedOn: string
  timeZone: string

  constructor(params?: Partial<NewArrivalJourneyEvent>) {
    super()
    this.waypointId = params?.waypointId ?? ''
    this.arrivedOn = params?.arrivedOn ?? ''
    this.timeZone = params?.timeZone ?? ''
    this.type = LiveTripEventType.ARRIVAL
    this.alerts = params?.alerts ?? []
  }

}
